import React from 'react';
import { useI18n } from '@paprika/l10n';
import GlobalNavigation, {
  CurrentApp,
  HelperWithPendo,
  LaunchpadLink,
  User,
  AppSwitcherSidePanel,
  KeyboardShortcutPanel,
} from '@acl-services/sriracha-global-navigation';

const mainSelector = '[role="main"]';

export default function GlobalNav({ navData }: { navData: any }) {
  const I18n = useI18n();

  if (!navData) return <GlobalNavigation mainContentSelector={mainSelector} />;

  const robotApp = navData.appSwitcherProps.apps.find((app) => app.key === 'robots');
  const organizationName = navData.appSwitcherProps.organizations.find(
    (org) => org.id === navData.appSwitcherProps.initialOrganizationId,
  ).name;

  return (
    <GlobalNavigation
      currentOrgSubscriptionType={navData.currentOrgSubscriptionType}
      locale={I18n.locale}
      mainContentSelector={mainSelector}
      zIndex={8}
    >
      <CurrentApp key="currentapp" appModuleName={robotApp.title} appUrl={robotApp.url} />
      <LaunchpadLink launchpadUrl={navData.links.launchpad} orgName={organizationName} />
      <HelperWithPendo key="helper" helpDocUrl={navData.links.help.homepage} supportUrl={navData.links.support} />
      <User
        key="user"
        customerName={navData.customerName}
        username={navData.userFullName}
        userProfileUrl={navData.links.profile}
        logoutUrl={navData.links.logout}
      />
      <AppSwitcherSidePanel key="appswitcher" {...navData.appSwitcherProps} />
      <KeyboardShortcutPanel appId="robotics" />
    </GlobalNavigation>
  );
}
