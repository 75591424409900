export const isTestEnvironmentProduction = () => window.Cypress && window.Cypress.env('flippers_env') === 'production';

export const isEnvironmentPreProd = (hostname = window.location.hostname) => {
  const [, , domain] = hostname.split('.');

  switch (domain) {
    case 'highbond-s2':
      return true;
    default:
      return false;
  }
};

export const isEnvironmentProduction = (hostname = window.location.hostname) => {
  const [, , domain] = hostname.split('.');
  switch (domain) {
    case 'highbond':
    case 'highbond-gov':
    case 'highbond-gov2':
    case 'highbond-gov3':
      return true;
    default:
      return false;
  }
};

export const isEnvironmentProductionLike = () => isEnvironmentProduction() || isEnvironmentPreProd();
