import { flowBuilderUrl } from 'localDev/localDevConfig.json';

const REGIONS = ['ap', 'eu', 'ca', 'au', 'af', 'sa', 'jp'];
const US_REGION = 'us';

export function getApiProxyUrl(currentUrl) {
  if (currentUrl.includes('localhost')) return '';

  const { domain, region, domainSuffix, tld } = getHighbondUrlParts(currentUrl);
  const regionSuffix = region && REGIONS.includes(region) ? region : US_REGION;
  const isProduction = domainSuffix === '';
  const isGovCloud = domain.includes('gov') || domain.includes('dod');
  const apiRegion = isProduction && !isGovCloud ? `-${regionSuffix}` : '';

  const hostname = `.${domain}${domainSuffix}.${tld}`;

  return `https://apis${apiRegion}${hostname}`;
}

export function getAccountUrl(currentUrl) {
  const highbondMatch = currentUrl.match(/\.highbond(-\w+)?(-\w+)?\.(local|com)$/);
  // if on localhost, use the local dev flow builder url
  const DOMAIN = highbondMatch
    ? highbondMatch.shift()
    : flowBuilderUrl.match(/\.highbond(-\w+)?\.(local|com)$/)?.shift();
  return `https://accounts${DOMAIN}`;
}

export function getMonitorUrl(currentUrl) {
  const urlParts = currentUrl.split('.');
  const domain = urlParts[2];
  const regionSplit = urlParts[1].split('-');
  const isPlayground = domain === 'highbond-s3';
  const region = regionSplit.length > 1 && !isPlayground ? `-${regionSplit[1]}` : '';
  return `https://monitor${region}.${domain}.com/v1/monitor.js`;
}

export function getRegion(currentUrl) {
  const urlParts = getHighbondUrlParts(currentUrl);

  return REGIONS.includes(urlParts.region) ? urlParts.region : US_REGION;
}

function getHighbondUrlParts(currentUrl) {
  const highbondUrlPattern = /flows-?(?<region>[-a-zA-Z0-9_]+)?\.(?<domain>highbond-gov|highbond-gov2|highbond-gov3|highbond)?(?<domainSuffix>-\w+)?\.(?<tld>local|com)$/;
  const highbondMatch = currentUrl.match(highbondUrlPattern);
  if (highbondMatch === null) return {};

  const region = highbondMatch?.groups?.region;
  const domain = highbondMatch?.groups?.domain;
  const domainSuffix = highbondMatch?.groups?.domainSuffix || '';
  const tld = highbondMatch?.groups?.tld;

  return { domain, domainSuffix, region, tld };
}
