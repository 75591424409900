import { v4 as uuidv4 } from 'uuid';
import { isLocalDev, isTesting, isStorybook } from 'localDev/localDevHelpers';

export default class WebSocketUtil {
  static getWebSocketUrl(windowLocation?: { origin: string }): string {
    if (isTesting() || isStorybook()) {
      return 'wss://main.flows-orc-us.highbond.local';
    }

    if (isLocalDev()) {
      return 'ws://localhost:8080';
    }

    if (windowLocation) {
      const url = windowLocation.origin;
      const match = url.match(/(\w+)?.flows([-]?)([-a-zA-Z0-9_]+)?\.highbond(-[-\w]+)?\.(local|com)$/);
      if (!match) {
        throw Error(`Unexpected URL. ${url}`);
      }

      const highbondEnvironment = match[4] ?? 'prod';
      let resourceSuffix,
        internalRegion = '';
      switch (highbondEnvironment.toLowerCase()) {
        case '-gov': {
          resourceSuffix = 'gov';
          internalRegion = 'usg';
          break;
        }
        case '-gov2': {
          resourceSuffix = 'sled';
          internalRegion = 'usg';
          break;
        }
        case '-gov3': {
          resourceSuffix = 'dod';
          internalRegion = 'usg';
          break;
        }
        case '-gov-s1': {
          resourceSuffix = 'gov';
          internalRegion = 'usg';
          break;
        }
        case '-s1': {
          resourceSuffix = 'main';
          internalRegion = 'us2';
          break;
        }
        case '-s2': {
          resourceSuffix = 'main';
          internalRegion = 'us';
          break;
        }
        case '-s3': {
          resourceSuffix = match[3] ? `main-${match[3]}` : 'main';
          internalRegion = 'us2';
          break;
        }
        default: {
          resourceSuffix = 'main';
          internalRegion = match[3] ?? 'us';
          break;
        }
      }
      const environmentSuffix = match[4] ?? '';
      const tld = match[5];
      return `wss://${resourceSuffix}.flows-orc-${internalRegion}.highbond${environmentSuffix}.${tld}`;
    }

    throw Error('windowLocation was not provided in production environment.');
  }

  static hasExistingSessionInBrowser(analyticId: string): boolean {
    const analyticIdToSessionId = WebSocketUtil.getAnalyticIdToSessionId();
    return analyticIdToSessionId.has(analyticId);
  }

  static generateSessionId() {
    return uuidv4();
  }

  static storeSessionIdUntilCloseTab(analyticId: string, sessionId: string) {
    if (isStorybook() || isTesting()) return;

    let analyticIdToSessionId: Map<string, string> = WebSocketUtil.getAnalyticIdToSessionId();
    analyticIdToSessionId.set(analyticId, sessionId);
    window.localStorage.setItem('analyticIdToSessionId', JSON.stringify([...analyticIdToSessionId]));
    window.addEventListener('unload', () => window.localStorage.removeItem('analyticIdToSessionId'));
  }

  private static getAnalyticIdToSessionId(): Map<string, string> {
    let analyticIdToSessionId: Map<string, string> = new Map(
      JSON.parse(window.localStorage.getItem('analyticIdToSessionId') || '[]'),
    );
    return analyticIdToSessionId || new Map<string, string>();
  }
}
